<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import store from "@/state/store";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    const permissions = store.getters["auth/permissions"];
    if (
      (userType === 1 || userType === 3) &&
      permissions.includes("PAYROLL_CONFIG")
    ) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Salary Increment",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  watch: {
  },
  mounted() {
    this.refreshTable();
  },
  validations: {
    attachment: { required },
    reason: { required },
  },
  methods: {
    refreshTable() {
      this.apiGet(`${this.ROUTES.salaryStructure}/salary-gross-archives`, "Get Salary Archive Error").then(
        (res) => {
          const { data } = res;
          data.map((sal)=>{
            this.salaryInc.push(sal)
          })
          this.totalRows = this.salaryInc.length;
        }
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectRow(row) {
      row = row[0];
      this.sgID = row.sg_id;
      this.name = row.sg_name;
      this.min = row.sg_minimum.toString();
      this.mid = row.sg_midpoint.toString();
      this.max = row.sg_maximum.toString();
      this.$refs["edit-salary-grade"].show();
      this.$refs["sg-table"].clearSelected();
    },
    resetForm() {
      this.name = null;
      this.min = null;
      this.mid = null;
      this.max = null;
      this.$v.$reset();
    },
    submitNew() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.apiFormHandler("Invalid Salary Grade");
      } else {
        const formData = new FormData();
        formData.append('attachment',this.attachment);
        formData.append('reason',this.reason);
          const url = `${this.ROUTES.salaryStructure}/run-salary-gross-archive`;
          this.apiPost(url, formData, "Salary Increment Error").then((res) => {
            console.log(res)
            this.apiResponseHandler("Salary Increment Upload Done!");
            this.refreshTable();
            this.$v.$reset();
            this.$refs["add-salary-grade"].hide();
          });
      }
    },
    filesChange(){
      this.attachment = this.$refs.file.files[0];
      //console.log(this.attachment);
    },
    processSalaryIncrement(){
      this.submitted = true;
      const formData = new FormData();
      formData.append('type',1); //0 for clear, 1=for process
      const url = `${this.ROUTES.salaryStructure}/process-salary-increment`;
      this.apiPost(url, formData, "Process Salary Increment Error").then((res) => {
        console.log(res)
        this.apiResponseHandler("Salary increment processed!");
        this.refreshTable();
        this.$v.$reset();
        this.$refs["process-prompt"].hide();
      });
    },
    clearSalaryIncrement(){
      this.submitted = true;
        const formData = new FormData();
        formData.append('type',0); //0 for clear, 1=for process
        const url = `${this.ROUTES.salaryStructure}/process-salary-increment`;
        this.apiPost(url, formData, "Clear Salary Increment Error").then((res) => {
          console.log(res)
          this.apiResponseHandler("Salary increment record cleared!");
          this.refreshTable();
          this.$v.$reset();
          this.$refs["clear-prompt"].hide();
        });
    },
  },
  data() {
    return {
      title: "Salary Archive",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Salary Archive",
          active: true,
        },
      ],
      salaryInc: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      fields: [
        { key: "sn", label: "S/n", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "new_gross", label: "New Gross", sortable: true },
        { key: "prev_gross", label: "Prev. Gross", sortable: true },
        { key: "reason", label: "Reason", sortable: true },
        { key: "tdate", label: "Date", sortable: true },
      ],
      submitted: false,
      sgID: null,
      name: null,
      min: null,
      mid: null,
      max: null,
      attachment: null,
      reason: null,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <scale-loader v-if="apiBusy" />
    <div v-else class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="sg-table"
                bordered
                selectable
                hover
                :items="salaryInc"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
                @row-selected="selectRow"
              >
                <template #cell(new_gross)="row" >
                  <span class="text-right" style="text-align: right;">{{row.value}}</span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="add-salary-grade"
      title="Salary Gross Archive"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="submitNew" enctype="multipart/form-data">
        <div class="form-group">
          <label for="reason">
            Reason <span class="text-danger">*</span>
          </label>
          <textarea
            id="reason"
            v-model="reason"
            class="form-control"
            style="resize: none;"
            placeholder="Enter reason for change in salary"
            :class="{
              'is-invalid': submitted && $v.reason.$error,
            }"
          />
        </div>
        <div class="form-group">
          <label for="attachment">
            File <span class="text-danger">*</span>
          </label>
          <input
            id="attachment"
            type="file"
            name="attachment"
            ref="file"
            @change="filesChange()"
            class="form-control-file"
          />
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>
    <b-modal
      ref="clear-prompt"
      title="Are you sure?"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="clearSalaryIncrement" enctype="multipart/form-data">
        <div class="form-group">
          <p>This action cannot be undone. Are you sure you want to <span class="text-danger">clear</span> salary increment?</p>
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Yes
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>
    <b-modal
      ref="process-prompt"
      title="Are you sure?"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="processSalaryIncrement" enctype="multipart/form-data">
        <div class="form-group">
          <p>This action cannot be undone. Are you sure you want to <span class="text-success">process</span> salary increment?</p>
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Yes
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>
  </Layout>
</template>
